// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth,createUserWithEmailAndPassword, onAuthStateChanged,signInWithEmailAndPassword } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBNbi5r5pjUz-6PuYMcc35TRpljMt7sogo",
  authDomain: "imzasoft-services.firebaseapp.com",
  projectId: "imzasoft-services",
  storageBucket: "imzasoft-services.appspot.com",
  messagingSenderId: "670494699479",
  appId: "1:670494699479:web:daf0e7abc7574ae4c4a592",
  measurementId: "G-H2YH9W816W"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app)
if(auth != null) {
  console.log('test')
} else {
  console.log('not findable');
}

// Initialize the FirebaseUI Widget using Firebase.
export function loginaccount(emaillogforac,passlogforacc) {
let emaillog = emaillogforac;
let passwordlog = passlogforacc;
  signInWithEmailAndPassword(auth, emaillog, passwordlog)
  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
  });
}
export function createaccount(emailforac,passforac) {
  let email = emailforac;
  let password = passforac;
  createUserWithEmailAndPassword(auth, email, password)

  .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    // ...
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ..
  });
}


if ( document.URL.includes("signup.html") ) {
  const buttontest = document.getElementById('signaccount');
const passwordfield = document.getElementById('password');
const emailfield = document.getElementById('emailad');
console.log(emailfield)
    console.log(passwordfield)
if(buttontest != null){
    console.log('buttonnotnull');
}
if(passwordfield != null){
    console.log('passwordfield');
}
if(emailfield != null){
    console.log('emailfield');
}

buttontest.onclick = function()
{
    console.log(emailfield)
    console.log(passwordfield)
    alert('Account Created');
    console.log('button');
    createaccount(emailfield.value,passwordfield.value)
    window.location.href = "./index.html";
}

} 
 if( document.URL.includes("login.html") ) {
  const button2 = document.getElementById('signaccount');
const passwordfield = document.getElementById('passwordlog');
const emailfield = document.getElementById('emaillog');
console.log(emailfield)
    console.log(passwordfield)
if(button2 != null){
    console.log('buttonnotnull');
}
if(passwordfield != null){
    console.log('passwordfield');
}
if(emailfield != null){
    console.log('emailfield');
}

button2.onclick = function()
{
    console.log(emailfield)
    console.log(passwordfield)
    alert('Logged In');
    console.log('sss');
    loginaccount(emailfield.value,passwordfield.value)
    
    
   

    
}
if(document.URL.includes('login.html')) {
  console.log('index');
  onAuthStateChanged(auth, (user) => {
    
    if (user) {

      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      console.log(user.userCredential);
      const uid = user.uid;
      window.location.href = "./index.html";
      // ...
    } else {
      // User is signed out
      // ...
    }
  });
  }
  if(auth.currentUser) {
    console.log('log again')
    const signupbutton = document.getElementById('signupbutton')
    const loginbutton = document.getElementById('loginbutton');
    const accountemail = document.getElementById('accountemail');
    signupbutton.hidden = true;
    loginbutton.hidden = true;
  }
}
if(auth.currentUser) {
  console.log('log again')
  const signupbutton = document.getElementById('signupbutton')
  const loginbutton = document.getElementById('loginbutton');
  signupbutton.hidden = true;
  loginbutton.hidden = true;
}
onAuthStateChanged(auth, (user) => {
    
  if (user) {
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/auth.user
    const signupbutton = document.getElementById('signupbutton');
    const loginbutton = document.getElementById('loginbutton');
    const accountemail = document.getElementById('accountemail')
    signupbutton.hidden = true;
    loginbutton.hidden = true;
    accountemail.style.visibility = 'hidden';
    

  
    
    const uid = user.uid;
    // ...
  } else {
    // User is signed out
    // ...
  }
});















  
  
  
